import React from "react"
import Layout from "../layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Video from "../components/Video"
import Introduction from "../components/Introduction"
import CarouselHome from "../components/CarouselHome"
import VideoDetail from "../components/VideoDetail"
import FeatureLine from "../components/FeatureLine"
import FormContact from "../components/FormContact"
import ControlPanel from "../components/ControlPanel/new"
import ControlPanelMobile from "../components/ControlPanel/mobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import FeatureLineMobile from "../components/FeatureLine/mobile"

import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

// ASSETS
import MainVideo from "../assets/videos/Homepage/GERAL.mp4"
import MainVideoPreview from "../assets/videos/Homepage/GERAL_preview.mp4"
import MainVideoMobile from "../assets/videos/Homepage/Mobile/GeralMOBILE.mp4"
import TurboramDetailVideo from "../assets/videos/Turboram/forno_turboram_detalhe.mp4"

const IndexPage = ({ location }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <Layout location={location} footer={t("footer", { returnObjects: true })} header={t("header", { returnObjects: true })}>
      <SEO
        title="Ramalhos - Pure Baking Pleasure"
        titleTemplate="%s"
        keywords={t("metaHome", { returnObjects: true })["keywords"]}
        description={t("metaHome", { returnObjects: true })["description"]}
        img={t("metaHome", { returnObjects: true })["image"]}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
        homepage={true}
      />
      {/* DESKTOP  */}
      {!breakpoints.tl && (
        <>
          <Video
            className="full-width full-height scrollable"
            srcp={MainVideo}
            src={MainVideoPreview}
            poster={"https://ramalhos.com/video_posters/Turboram/forno_turboram.jpg"}
            width="100%"
            id="video-homepage"
            fullString={t("videoHome", { returnObjects: true })["info"]}
            toClick="#introduction"
            isHomePage
            isEvent={false}
          />
          <Introduction
            className="scrollable"
            id="introduction"
            translation={
              activeLanguage === "br" 
                ? t("introductionHome", { returnObjects: true })
                : t("configuradoresHome", { returnObjects: true })
            }
            lang={activeLanguage}
            homepage
            br
          />
          <CarouselHome
            showArrows={false}
            showIndicators={true}
            autoPlay={false}
            transitionTime={0}
            id="carousel-homepage"
            sliderButton
            carouselContent={t("carouselHome", { returnObjects: true })}
          />
          <FeatureLine
            id="homepage-feature"
            _title={t("featureHome", { returnObjects: true })["title1"]}
            text={t("featureHome", { returnObjects: true })["text1"]}
            textWidth="100%"
            positionCenter
          />
          <ControlPanel
            id="control-panel-homepage"
            carouselContent={t("panel", { returnObjects: true })["panels"]}
            _title={{
              title1: t("panel", { returnObjects: true })["title1"],
              title2: t("panel", { returnObjects: true })["title2"],
            }}
          />
          <VideoDetail
            src={TurboramDetailVideo}
            id="video-detail-turboram"
            _title={t("videoHome", { returnObjects: true })["title1"]}
            text1={t("videoHome", { returnObjects: true })["text1_1"]}
            text2={t("videoHome", { returnObjects: true })["text1_2"]}
          />
        </>
      )}

      {/* MOBILE  */}
      {breakpoints.tl && (
        <>
          <Video
            className="full-width full-height scrollable"
            srcp={MainVideoMobile}
            src={MainVideoPreview}
            poster={"https://ramalhos.com/video_posters/Turboram/forno_turboram.jpg"}
            width="100%"
            id="video-turboram"
            imgMobile={t("videoHome", { returnObjects: true })["bgMobile"]}
            fullString="Ver vídeo completo"
            toClick="#introduction"
            mobile={true}
            isHomePage
            isEvent={false}
          />
           <Introduction
            className="scrollable"
            id="introduction"
            translation={
              activeLanguage === "br"
                ? t("introductionHome", { returnObjects: true })
                : t("configuradoresHome", { returnObjects: true })
            }
            lang={activeLanguage}
            homepage
            br
          />
          <FeatureLineMobile
            id="first-line-mobile"
            _title={t("carouselMobileHome", { returnObjects: true })["title1"]}
            text1={t("carouselHome", { returnObjects: true })["sliders"][0]["text"]}
            homepage
            image={t("featureHome", { returnObjects: true })["image1_1M"]}
            sliderBtn
            btnText={t("carouselMobileHome", { returnObjects: true })["button1"]}
            btnLink={t("carouselMobileHome", { returnObjects: true })["link1"]}
          />
          <FeatureLineMobile
            id="second-line-mobile"
            _title={t("carouselMobileHome", { returnObjects: true })["title2"]}
            text1={t("carouselHome", { returnObjects: true })["sliders"][1]["text"]}
            homepage
            image={t("featureHome", { returnObjects: true })["image1_2M"]}
            sliderBtn
            btnText={t("carouselMobileHome", { returnObjects: true })["button2"]}
            btnLink={t("carouselMobileHome", { returnObjects: true })["link2"]}
          />
          <FeatureLineMobile
            id="third-line-mobile"
            _title={t("carouselMobileHome", { returnObjects: true })["title3"]}
            text1={t("carouselHome", { returnObjects: true })["sliders"][2]["text"]}
            homepage
            image={t("featureHome", { returnObjects: true })["image2_1M"]}
            sliderBtn
            btnText={t("carouselMobileHome", { returnObjects: true })["button3"]}
            btnLink={t("carouselMobileHome", { returnObjects: true })["link3"]}
          />
          <FeatureLineMobile
            id="fourth-line-mobile"
            _title={t("featureHome", { returnObjects: true })["title1"]}
            text1={t("featureHome", { returnObjects: true })["text1"]}
            positionCenter
          />
          <ControlPanelMobile
            id="control-panel-mobile-homepage"
            _title={{
              title1: t("panel", { returnObjects: true })["title1"],
              title2: t("panel", { returnObjects: true })["title2"],
            }}
            carouselContent={t("panel", { returnObjects: true })["panels"]}
          />
          <FeatureLineMobile
            id="fifth-line-mobile"
            _title={t("carouselMobileHome", { returnObjects: true })["title4"]}
            image={t("featureHome", { returnObjects: true })["image2_2M"]}
            sliderBtn
            btnText={t("carouselMobileHome", { returnObjects: true })["button4"]}
            btnLink={t("carouselMobileHome", { returnObjects: true })["link4"]}
          />
          <FeatureLineMobile
            id="sixth-line-mobile"
            _title={t("carouselMobileHome", { returnObjects: true })["title5"]}
            image={t("featureHome", { returnObjects: true })["image3_1M"]}
            sliderBtn
            btnText={t("carouselMobileHome", { returnObjects: true })["button5"]}
            btnLink={t("carouselMobileHome", { returnObjects: true })["link5"]}
          />
        </>
      )}
      {!(activeLanguage === "br" || activeLanguage === "mx") && (
        <>
           <Introduction
            className="scrollable"
            id="introduction"
            translation={t("introductionHome", { returnObjects: true })}
            lang={activeLanguage}
            homepage
            br
            smallerSpacing
          />
          <FormContact
            id="form-contact"
            info={t("form", { returnObjects: true })}
            lang={activeLanguage}
            location={location}
            smallerSpacing
          />
        </>
      )}
    </Layout>
  )
}

export default IndexPage

export const QueryHomepagePT = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["home", "turboram", "form", "footer", "header"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
